import React, {useRef, useEffect} from "react";
import {Canvas, useFrame, extend} from "@react-three/fiber";
import {OrbitControls} from "@react-three/drei";
import * as THREE from "three";
import {useMediaQuery, useTheme} from "@mui/material";

// Extend Three.js components for React
extend({OrbitControls});

// const WaveShader = () => {
//     const meshRef = useRef<THREE.Points>(null!);
//     const uniforms = useRef({
//         u_time: {value: 0.0},
//         u_pointsize: {value: 2.0},
//         u_noise_freq: {value: 3.0},
//         u_noise_amp: {value: 0.2},
//         u_pole_distortion: {value: 0.3}, // Distortion amount for poles
//     });
//
//     const vertexShader = `
//     uniform float u_time;
//     uniform float u_pointsize;
//     uniform float u_noise_amp;
//     uniform float u_noise_freq;
//     uniform float u_pole_distortion;
//
//     float random (in vec2 st) {
//         return fract(sin(dot(st.xy,
//                             vec2(12.9898,78.233)))
//                     * 43758.5453123);
//     }
//
//     float noise (in vec2 st) {
//         vec2 i = floor(st);
//         vec2 f = fract(st);
//
//         float a = random(i);
//         float b = random(i + vec2(1.0, 0.0));
//         float c = random(i + vec2(0.0, 1.0));
//         float d = random(i + vec2(1.0, 1.0));
//
//         vec2 u = f*f*(3.0-2.0*f);
//
//         return mix(a, b, u.x) +
//                 (c - a)* u.y * (1.0 - u.x) +
//                 (d - b) * u.x * u.y;
//     }
//
//     void main() {
//         gl_PointSize = u_pointsize;
//
//         vec3 pos = position;
//
//         // Apply noise to distort icosahedron
//         pos += normal * noise(pos.xy * u_noise_freq + u_time) * u_noise_amp;
//
//         // Add distortion to poles
//         float poleFactor = 1.0 - abs(normal.y); // Closer to 1.0 near poles
//         pos += normal * poleFactor * u_pole_distortion;
//
//         vec4 mvm = modelViewMatrix * vec4(pos, 1.0);
//         gl_Position = projectionMatrix * mvm;
//     }
//   `;
//
//     const fragmentShader = `
//     #ifdef GL_ES
//     precision mediump float;
//     #endif
//
//     void main() {
//         // gl_FragColor = vec4(0.10588, 0.07843, 0.39216, 1.0); // RGBA for #1B1464 (Navy Blue)
//         gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0); // RGBA for #1B1464 (Navy Blue)
//     }
//   `;
//
//     useFrame(({clock}) => {
//         uniforms.current.u_time.value = clock.getElapsedTime();
//
//         // Slowly rotate the mesh
//         if (meshRef.current) {
//             meshRef.current.rotation.y += 0.005; // Rotate horizontally
//             meshRef.current.rotation.x += 0.003; // Rotate vertically
//         }
//     });
//
//     return (
//         <points ref={meshRef}>
//             <icosahedronGeometry args={[2, 20]}/>
//             <shaderMaterial
//                 uniforms={uniforms.current}
//                 vertexShader={vertexShader}
//                 fragmentShader={fragmentShader}
//                 depthTest // Ensures proper depth rendering
//                 depthWrite // Ensures proper depth writing
//             />
//         </points>
//     );
// };

const WaveShader = () => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md")); // Check if screen is at least "md" size


    const meshRef = useRef<THREE.Points>(null!);
    const uniforms = useRef({
        u_time: { value: 0.0 },
        u_pointsize: { value: isMdUp ? 2.0 : 1.0 },
        u_texture: { value: null }, // Uniform for heightmap texture
        u_height_scale: { value: 0.2 }, // Scale factor for elevation
        u_wiggle_amplitude: { value: 0.02 }, // Amplitude of wiggle
        u_wiggle_frequency: { value: 0.5 }, // Frequency of wiggle
    });

    const vertexShader = `
    uniform sampler2D u_texture;
    uniform float u_height_scale;
    uniform float u_time;
    uniform float u_wiggle_amplitude;
    uniform float u_wiggle_frequency;
    uniform float u_pointsize;

    varying vec2 v_uv; // Pass UV to fragment shader

    float random (in vec2 st) {
        return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }

    float noise (in vec2 st) {
        vec2 i = floor(st);
        vec2 f = fract(st);

        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));

        vec2 u = f * f * (3.0 - 2.0 * f);

        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    void main() {
        v_uv = uv;

        vec3 pos = position;

        // Sample elevation data from texture using UV coordinates
        float elevation = texture2D(u_texture, uv).r; // Use red channel for height
        pos += normal * elevation * u_height_scale; // Displace vertices based on heightmap

        // Add random wiggle effect on u-axis
        float wiggleU = sin(u_time * u_wiggle_frequency + noise(pos.xy * 10.0)) * u_wiggle_amplitude;
        pos += normal * wiggleU;

        // Add random wiggle effect on v-axis
        float wiggleV = cos(u_time * (u_wiggle_frequency + 1.0) + noise(pos.yz * 12.0)) * u_wiggle_amplitude;
        pos += normal * wiggleV;

        vec4 mvm = modelViewMatrix * vec4(pos, 1.0);
        gl_Position = projectionMatrix * mvm;

        gl_PointSize = u_pointsize;
    }
  `;

    const fragmentShader = `
    varying vec2 v_uv;

    void main() {
        // Color based on UV coordinates for testing
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
    }
  `;

    useEffect(() => {
        // Load the heightmap texture
        new THREE.TextureLoader().load(
            '/heightmap.jpg',
            (texture) => {
                // @ts-ignore
                uniforms.current.u_texture.value = texture;
            }
        );
    }, []);

    useFrame(({ clock }) => {
        uniforms.current.u_time.value = clock.getElapsedTime();

        // Slowly rotate the mesh
        if (meshRef.current) {
            meshRef.current.rotation.y += 0.002; // Rotate horizontally
            meshRef.current.rotation.x += 0.001; // Rotate vertically
        }
    });

    return (
        <points ref={meshRef}>
            {/* Icosahedron Geometry */}
            <icosahedronGeometry args={[2, (isMdUp ? 60 : 30)]} />
            {/* Shader Material */}
            <shaderMaterial
                uniforms={uniforms.current}
                vertexShader={vertexShader}
                fragmentShader={fragmentShader}
                depthTest
                depthWrite
            />
        </points>
    );
};

interface WaveSceneProps {
    size?: number; // Accept a number for size
}
const WaveScene: React.FC<WaveSceneProps> = ({ size = 400 }) => {
    return (
        <Canvas
            style={{
                height: (size ?? 400),
                width: size ?? 400,
                margin:'auto',
            }}
            camera={{
                position: [0, 0, 5], fov: 60,
                near: 0.1,
                far: 4.2,
            }}
            gl={{toneMapping: THREE.NoToneMapping}}
        >
            {/* OrbitControls for user interaction */}
            {/*<OrbitControls enableDamping />*/}

            {/* Ambient light with high intensity */}
            <ambientLight color={"white"} intensity={2.0}/>

            {/* White base icosahedron */}
            {/*<BaseIcosahedron/>*/}

            {/* Dynamic navy-blue points */}
            <WaveShader/>
        </Canvas>
    );
};

export default WaveScene;