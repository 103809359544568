import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import Store from "../store";
import ErrorMessage from "./error";
import {Appearance, loadStripe} from "@stripe/stripe-js";
import {AddressElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {PaymentElement} from '@stripe/react-stripe-js';
import Loading from "./loading";
import {determineLocation} from "../assets/determineLocation";
import * as stripeJs from "@stripe/stripe-js";
import Spacer from "./Spacer";
import {Container, Divider, Typography} from "@mui/material";
import {DarkButton} from "./buttons";
import {FyleproDivider} from "./fylepro-divider";
import {main} from "../assets/colors";
import RenewalStore from "../stores/renewal-store";

// const stripePromise = loadStripe('pk_test_51Opcd1AWzpu8ZpF6kpLmJnyDS9EGV9RdQGn3ccF1r4i12HbYG5Ymxt4rOZXj507S1R3k6WhP0qcZKujGKlr2589X0006w9xWG3');
const stripePromise = loadStripe('pk_live_51Opcd1AWzpu8ZpF6Qqb9QkvWB48bhhMTG3MMTGKy8zoK0hOQQ7nIvEmFypuHwyscJatUIfrlh0mMDOtwyH8N4AqV00vFZKE9XL');

const RenewStepTwo = ({companyInfo}: { companyInfo: any }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmed, setConfirmed] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const appearanceOptions: Appearance = {
        theme: 'flat',
        variables: {
            fontWeightNormal: '500',
            borderRadius: '2px',
            colorBackground: 'white',
            colorPrimary: main,
            colorPrimaryText: 'white',
            spacingGridRow: '15px'
        },
        rules: {
            '.Label': {
                marginBottom: '6px'
            },
            '.Tab, .Input, .Block': {
                boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                padding: '12px'
            }
        }
    };

    function onCheckoutClick(){
        console.log("clicked here");
    }

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        const handleSubmit = async (event) => {
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: `http://${window.location.host}/complete`,
                },
            });


            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                console.log(result.error.message);
                setErrorMessage(result.error.message ?? "");
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                Store.setRenewStep(3);
            }
        };


        return (
            <form onSubmit={handleSubmit}>
                <FyleproDivider textAlign="left" sx={{m: 2}}>Billing Information</FyleproDivider>
                <PaymentElement options={{
                    fields: {
                        billingDetails: {
                            name: 'auto',
                            email: 'auto',
                            address: 'auto',
                        }
                    }
                }}/>
                <FyleproDivider textAlign="left" sx={{m: 2}}>Billing Address</FyleproDivider>
                <AddressElement options={{mode: 'billing'}}/>
                <Container maxWidth={"sm"} sx={{display: 'flex'}}>
                    <DarkButton type='submit' disabled={!stripe} sx={{p: 2, mt: 3, width: '100%'}} disableElevation
                                variant={'contained'}>Submit Payment</DarkButton>

                </Container>
            </form>
        )
    };

    React.useEffect(() => {
        console.log("fetch");
        fetch(`${determineLocation()}/billing/${companyInfo.id}/payment`, {method: 'POST'})
            .then(x => x.json()
                .then((body) => {
                    setConfirmed(true);
                    setClientSecret(body.message);
                }))
    });

    useEffect(() => {
        //Scroll to top of page
        window.scrollTo(0, 0);
    }, [Store.getRenewStep]);

    if (!confirmed) {
        return <Loading/>
    }

    const options: stripeJs.StripeElementsOptions = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
        appearance: appearanceOptions,
    };

    return (
        <>
            <FyleproDivider textAlign="left" sx={{m: 2}}>Filing Fees</FyleproDivider>
            <FilingFees/>
            <Spacer height={10}/>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm/>
            </Elements>
            <ErrorMessage message={errorMessage}/>
        </>
    )


    function setNextStep() {
        if (Store.getSelectedOfficer !== null) {
            Store.setRenewStep(3)
        } else {
            setErrorMessage('Please select an officer');
        }
    }


}

const FilingFees = () => {
    return <Container maxWidth={false} disableGutters>
        {(RenewalStore.getCompanyInfo.serviceFee.discounted) ?
        <>
            <Container disableGutters maxWidth={false} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                my: 1,
                textDecoration: 'line-through',
                textDecorationColor: 'red',
                textDecorationThickness: '2px'
            }}>
                <Typography>Service Fee</Typography>
                <Typography>${parseFloat(String(RenewalStore.getCompanyInfo.serviceFee.originalAmount/100)).toFixed(2)}</Typography>
            </Container>
            <Container disableGutters maxWidth={false} sx={{display: 'flex', justifyContent: 'space-between', my: 1, fontWeight:'900', color:'red'}}>
                <Typography sx={{fontWeight:'inherit', color:'inherit'}}>Early File Fee</Typography>
                <Typography sx={{fontWeight:'inherit', color:'inherit'}}>{RenewalStore.getCompanyInfo.serviceFee.output}</Typography>
            </Container>
        </> : <>
                <Container disableGutters maxWidth={false} sx={{display: 'flex', justifyContent: 'space-between', my: 1}}>
                    <Typography>Filing Fee</Typography>
                    <Typography>{RenewalStore.getCompanyInfo.serviceFee.output}</Typography>
                </Container>
            </>
        }

        <Container disableGutters maxWidth={false} sx={{display: 'flex', justifyContent: 'space-between', my: 1}}>
            <Typography>State Fee</Typography>
            <Typography>{RenewalStore.getCompanyInfo.filingFee.output}</Typography>
        </Container>
        <Divider />
        <Container disableGutters maxWidth={false} sx={{display: 'flex', justifyContent: 'space-between', my: 1}}>
            <Typography>TOTAL</Typography>
            <Typography>${parseFloat(String((RenewalStore.getCompanyInfo.filingFee.amount + RenewalStore.getCompanyInfo.serviceFee.amount) / 100)).toFixed(2)}</Typography>
        </Container>
    </Container>
}


export default RenewStepTwo