import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {background, black} from "../assets/colors";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {logout} from "../api/client";

const pagesNew = {
    'My Profile':['/dashboard', 'navigate'],
    'FAQ':['FAQ', 'jump'],
    'Contact':['Contact', 'jump']
};

function ResponsiveAppBarLoggedIn() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.hash != '') {
            console.log("effect");
            scrollIntoView(window.location.hash.replace('#', ''), "jump");
        }
    });

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = ({location = null, type}: { location: string | null, type:string }) => {
        setAnchorElNav(null);
        if (location != null) {
            scrollIntoView(location, type);
        }

    };

    const scrollIntoView = (location: string, type: string) => {
        //If we are not at that page, we should go there.
        if (window.location.pathname != '/' || type == "navigate") {

            navigate((type == "jump" ? '/#' : '') + location);
        }
        if (type == "jump") {
            document.getElementById(location)!.scrollIntoView();
            window.history.replaceState(null, "", "/")

        }
    }

    const handleLogout = () => {
        logout().then(()=>{
            navigate('/');
        });

    };

    return (
        <AppBar position="sticky">
            <Container maxWidth={false} disableGutters style={{backgroundColor: "white", color: black}}>
                <Toolbar variant={'dense'} disableGutters sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                    <Box component={"div"} sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <a href={"/"}>
                            {/*<img src={logoWide} style={{marginLeft: 10, height: 30}} alt={"FylePro"}/>*/}
                        </a>
                    </Box>

                </Toolbar>
                <Toolbar variant={'dense'} disableGutters sx={{backgroundColor: {xs: 'white', md: background}}}>
                    <Box component={"div"} sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {Object.keys(pagesNew).map((page) => (
                                <MenuItem key={page} onClick={() => {
                                    handleCloseNavMenu({location: pagesNew[page][0], type: pagesNew[page][1]})
                                }}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem key={"logout"} onClick={handleLogout}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box component={"div"} sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {Object.keys(pagesNew).map((page) => (
                            <Button
                                key={page}
                                onClick={() => {
                                    handleCloseNavMenu({location: pagesNew[page][0], type: pagesNew[page][1]})
                                }}
                                sx={{color: black, display: 'block', px: 7}}
                            >
                                {page}
                            </Button>
                        ))}

                        <Button
                            key={"logout"}
                            onClick={handleLogout}
                            sx={{color: black, display: 'block', px: 7}}
                        >
                            Logout
                        </Button>
                    </Box>

                    <Box component={"div"} sx={{display: {xs: 'flex', md: 'none'}, flexGrow: 1}}>
                        {/*<img src={logoChip} style={{marginLeft: 10, height: 30}} alt={"FylePro"}/>*/}
                    </Box>
                    <Box component={"div"} sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBarLoggedIn;