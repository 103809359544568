import {observer} from "mobx-react";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import RenewalStore from "../../stores/renewal-store";
import React, {ChangeEvent, useEffect, useState} from "react";

const ChangeFeinComponent = observer(({manageFormState}: { manageFormState: any }) => {
    const [feinData, setFeinData] = useState(RenewalStore.getUpdatedCompanyInfo.fein);
    const [errorMessage, setErrorMessage] = useState("");
    const [radioValue, setRadioValue] = React.useState('female');



    useEffect(()=>{
        setRadioValue(feinData==="Applied For" ? "applied" : feinData==="Not Applicable" ? "na" : "provided");
        if(feinData === "Please Provide EIN"){
            setFeinData("");
        }
    }, [])

    const handleEinChanged = async (event: ChangeEvent<HTMLInputElement>) => {
        setFeinData((event.target as HTMLInputElement).value);
        manageFormState(event, 'fein');
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
        manageFormState(event, 'fein');

    };


    return (
        <>
            <TextField disabled={feinData === "Applied For" || feinData === "Not Applicable"}
                       id={"fein-change"} fullWidth label={'EIN'} margin="normal" variant="outlined"
                       value={feinData}
                       onChange={handleEinChanged}/>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={radioValue}
                onChange={handleRadioChange}
            >
                <FormControlLabel value="provided" control={<Radio />} onClick={()=>{
                    setFeinData("");
                }} label="Listed Above" />
                <FormControlLabel  value="applied" control={<Radio />} onClick={()=>{
                    setFeinData("Applied For");
                }} label="Applied For" />
                <FormControlLabel value="na" control={<Radio />} onClick={()=>{
                    setFeinData("Not Applicable");
                }} label="Not Applicable" />
            </RadioGroup>
        </>
    )

})

export default ChangeFeinComponent