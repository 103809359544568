import React from "react";
import {Container, Typography} from "@mui/material";
import Spacer from "./Spacer";
import Footer from "./home/Footer";
import ResponsiveAppBarNew from "./responsive-app-bar-new";

function CompanyNotFound(){
    return (<>
        <ResponsiveAppBarNew/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Typography align={"center"}>
                Company not found. Please check document number.
            </Typography>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);
}

export default CompanyNotFound;