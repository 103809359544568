import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useMediaQuery,
    useTheme,
    Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoWhite from "../assets/logo-white.svg";

interface Page {
    title: string;
    link: string;
}

const pagesLeft: Page[] = [
    { title: "WHY INCORPORATE?", link: "/#why-incorporate" },
    { title: "TYPES OF CORPS", link: "/#type-of-corps" },
];

const pagesRight: Page[] = [
    { title: "OUR SERVICES", link: "/#our-services" },
    { title: "PRICING", link: "/#pricing" },
];

function ResponsiveAppBarNew({ shrinkable = false }: { shrinkable?: boolean }) {
    const blueColor = "#242144";
    const [navbarBg, setNavbarBg] = useState(shrinkable ? blueColor : "white");
    const [navbarIconSize, setNavbarIconSize] = useState(shrinkable ? 125 : 75);
    const [navbarIcon, setNavbarIcon] = useState(
        shrinkable ? logoWhite : logo
    );
    const [navbarPaddingSize, setNavbarPaddingSize] = useState(shrinkable ? 2 : 1);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (!shrinkable) {
            setNavbarBg("white");
            setNavbarIconSize(75);
            setNavbarIcon(logo);
            return; // No need to add scroll listeners if shrinkable is false
        }

        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 100) {
                setNavbarBg("white");
                setNavbarIconSize(75);
                setNavbarIcon(logo);
                setNavbarPaddingSize(1);
            } else {
                setNavbarBg(blueColor);
                setNavbarIconSize(125);
                setNavbarIcon(logoWhite);
                setNavbarPaddingSize(2);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Initialize on mount

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [location, shrinkable]);

    const handleDrawerToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const renderDesktopMenu = (pages: Page[], justify: string) => (
        <Container
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: justify,
                gap: 2,
            }}
        >
            {pages.map((page) => (
                <Button
                    key={page.title}
                    sx={{
                        color: navbarBg === blueColor ? "#fff" : blueColor,
                        fontWeight: "bold",
                    }}
                    href={page.link}
                >
                    {page.title}
                </Button>
            ))}
        </Container>
    );

    const renderMobileMenu = () => (
        <Drawer
            anchor="right"
            open={mobileMenuOpen}
            onClose={handleDrawerToggle}
        >
            <Container
                sx={{ width: 250 }}
                role="presentation"
                onClick={handleDrawerToggle}
                onKeyDown={handleDrawerToggle}
            >
                <List>
                    {[...pagesLeft, ...pagesRight].map((page, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={() => navigate(page.link)}>
                                <ListItemText primary={page.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Container>
        </Drawer>
    );

    return (
        <>
            <AppBar
                position= {shrinkable ? "fixed" : "sticky"}
                sx={{
                    backgroundColor: navbarBg,
                    transition: shrinkable
                        ? "background-color 0.3s ease"
                        : "none",
                }}
                elevation={0}
            >
                <Toolbar
                    sx={{
                        minHeight: 64,
                        display: "flex",
                        justifyContent: { xs: "space-between", md: "center" },
                    }}
                >
                    {!isMobile && renderDesktopMenu(pagesLeft, "flex-end")}

                    <Box
                        component="img"
                        src={navbarIcon}
                        alt="Logo"
                        sx={{
                            paddingTop: navbarPaddingSize,
                            paddingBottom: navbarPaddingSize,
                            marginLeft: 2,
                            marginRight: 2,
                            width: navbarIconSize,
                            maxWidth: 300,
                            transition: shrinkable ? "all 0.3s ease" : "none",
                        }}
                    />

                    {isMobile ? (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        renderDesktopMenu(pagesRight, "flex-start")
                    )}
                </Toolbar>
            </AppBar>
            {renderMobileMenu()}
        </>
    );
}

export default ResponsiveAppBarNew;