import {action, computed, makeAutoObservable, makeObservable, observable} from "mobx";
import {validateTokenExpiration} from "./api/client";
import {useNavigate} from "react-router-dom";

class Store {

    selectedOfficer = null;
    renewStep = 1;
    signatureHash = '';
    loggedIn = false;
    gettingRefreshToken = false;

    constructor() {
        // makeAutoObservable(this)
        makeObservable(this, {
            selectedOfficer: observable,
            renewStep: observable,
            signatureHash: observable,
            loggedIn: observable,
            gettingRefreshToken: observable,
            setSelectedOfficer: action.bound,
            setRenewStep: action.bound,
            setSignatureHash: action.bound,
            setLoggedIn: action.bound,
            setLoggedOut: action.bound,
            setGettingRefreshToken: action.bound,
            // removeItem: action.bound,
            // count: computed,
        });
    }

    setSelectedOfficer = (officerId: any) => {
        // console.log(`Setting officer ${officerId}`)
        this.selectedOfficer = officerId;
    };

    get getSelectedOfficer() {
        return this.selectedOfficer;
    }

    setRenewStep = (renewStep: number) => {
        this.renewStep = renewStep;
    };

    get getRenewStep() {
        return this.renewStep;
    }

    setSignatureHash = (signatureHash: string) => {
        this.signatureHash = signatureHash;
    }

    get getSignatureHash() {
        return this.signatureHash;
    }

    setLoggedIn = () => {
        this.loggedIn = true;
    }

    setLoggedOut = () => {
        this.loggedIn = false;
    }

    get isLoggedIn() {
        this.getLoggedIn();
        return this.loggedIn;
    }

    getLoggedIn = () => {
        // console.log('get logged in');
        validateTokenExpiration().then((result) => {
            // console.log(result);
            if (result) {
                this.setLoggedIn();
            } else {
                this.setLoggedOut();
            }
            // return this.loggedIn;
        })

    }

    setGettingRefreshToken = (gettingBool) => {
        this.gettingRefreshToken = gettingBool;
    }

    get getGettingRefreshToken(){
        return this.gettingRefreshToken;
    }


}

export default new Store();