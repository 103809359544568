import React, {Component} from "react";
import {observer} from "mobx-react";
import {Container, FormControl, InputLabel, TextField} from "@mui/material";
import RenewalStore from "../../stores/renewal-store";
import {CompactDarkButton, InlineButton} from "../buttons";
import editButtonAction from "../../ui/renewal/renewal-edit-button-action";

class OfficersList extends Component<{ officers: any }> {
    render() {
        let {officers} = this.props;
        // console.log(officers);
        return (
            <Container key={JSON.stringify(officers)} disableGutters sx={{display:'grid', gridTemplateColumns:{xs: 'repeat(1, 1fr)', md:'repeat(2, 1fr)'}, gridColumnGap:{xs: 16, md: 48}}}>
                {RenewalStore.getUpdatedCompanyInfo.officers.map((officer: { id: number; title: string | null; type: string | null; firstName: string | null; lastName: string | null; middleName: string | null; titleName: string | null; address: string | null; city: string | null; state: string | null; zipCode: string | null; }, index: number) =>
                    <OfficeElement index={index} key={JSON.stringify(officer)}/>)}
            </Container>
        );
    }
}

const OfficeElement = observer(
    ({index}: {index:number }) => {
        return (
            <Container disableGutters sx={{display:'flex', flexDirection:'column', textDecoration:RenewalStore.getUpdatedCompanyInfo.officers[index].remove == true ? "line-through": undefined }}>
                <FormControl>
                    <InputLabel shrink sx={{marginLeft:"-5px", width: 'calc(133% - 32px)'}}>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div style={{background: "white", paddingLeft: 5, paddingRight: 10}}>
                                <span>{'Title: ' + RenewalStore.getUpdatedCompanyInfo.officers[index].title}</span>
                                {RenewalStore.getUpdatedCompanyInfo.officers[index].remove == undefined ? <CompactDarkButton onClick={() => editButtonAction({
                                    field: "officer",
                                    index: index
                                })}>Change</CompactDarkButton> : <></>}
                            </div>
                            <div style={{background: "white", paddingRight: 10}}>
                                <InlineButton onClick={() => {
                                    if(RenewalStore.getUpdatedCompanyInfo.officers[index].remove == true){
                                        delete RenewalStore.getUpdatedCompanyInfo.officers[index].remove;
                                    }else{
                                        RenewalStore.getUpdatedCompanyInfo.officers[index].remove = true
                                    }
                                }}>{RenewalStore.getUpdatedCompanyInfo.officers[index].remove == true ? "Restore" : "Remove"}</InlineButton>
                            </div>
                        </div>

                    </InputLabel>
                    <TextField id={`test${index}`} disabled
                        //                label={
                        //     <>
                        //     <span>{'Title: ' + RenewalStore.getUpdatedCompanyInfo.officers[index].title}</span>
                        //     <InlineButton onClick={() => editButtonAction({field:"officer", index:index})}>Change</InlineButton>
                        //     </>
                        // }
                                   value={`${RenewalStore.getUpdatedCompanyInfo.officers[index].firstName} ${RenewalStore.getUpdatedCompanyInfo.officers[index].lastName}`}/>

                </FormControl>
                <TextField disabled margin='dense' value={`${RenewalStore.getUpdatedCompanyInfo.officers[index].address} `}/>
                <TextField sx={{mb:3}} disabled margin='dense' value={`${RenewalStore.getUpdatedCompanyInfo.officers[index].city}, ${RenewalStore.getUpdatedCompanyInfo.officers[index].state} ${RenewalStore.getUpdatedCompanyInfo.officers[index].zipCode}`}/>
            </Container>
        )
    }
)


export default OfficersList