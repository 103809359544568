import React from "react";
import {Container, Typography} from "@mui/material";
import Spacer from "./Spacer";
import Footer from "./home/Footer";
import ResponsiveAppBarNew from "./responsive-app-bar-new";

function AlreadyRenewed(){
    return (<>
        <ResponsiveAppBarNew/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Typography align={"center"}>
                Per our records your company's Annual Report has already been filed with the Department of State.
            </Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                We remain at your disposal to handle your Annual Report filings with our directed service in the future via your company profile on our website.
            </Typography>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);
}

export default AlreadyRenewed