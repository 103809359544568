import {action, computed, makeObservable, observable} from "mobx";
import {ChangeEvent} from "react";
import diff from "recursive-diff";

export interface RenewalStoreHandleFieldProps {
    fieldName: string,
    fieldIndex?: number|null,
}

class RenewalStore {
    companyInfo: any;
    updatedCompanyInfo: any;
    updatedFein: any;


    /*All new stuff goes here*/

    constructor() {
        makeObservable(this, {
            updatedCompanyInfo: observable,
            updatedFein: observable,
            setFein: action.bound,
            handleFieldChange: action.bound,
            setCompanyInfo: action.bound,
            setUpdatedCompanyInfo: action.bound,
            replaceUpdatedCompanyInfoField:action.bound,
            replaceUpdatedCompanyInfoOfficer:action.bound,
            /*All new stuff goes here*/
        });
    }

    /*Old stuff below here*/

    setFein = (fein: any) => {
        this.updatedFein = fein;
    }

    get getFein(){
        return this.updatedFein;
    }

    setCompanyInfo = (companyInfo: any) => {
        this.companyInfo = companyInfo;
    }

    setUpdatedCompanyInfo = (updatedCompanyInfo: any) => {
        this.updatedCompanyInfo = updatedCompanyInfo;
    }

    replaceUpdatedCompanyInfoField = (field:string, data: any) => {
        this.updatedCompanyInfo[field] = data;
    }

    replaceUpdatedCompanyInfoOfficer = (index:number, data:any) => {
        let tmp = JSON.parse(JSON.stringify(this.updatedCompanyInfo['officers']));
        tmp[index] = data;
        this.replaceUpdatedCompanyInfoField('officers', tmp);
    }

    get getCompanyInfo() {
        return this.companyInfo;
    }

    get getUpdatedCompanyInfo() {
        return this.updatedCompanyInfo;
    }

    handleFieldChange = (fieldData:RenewalStoreHandleFieldProps, event: ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
        let newValue = (event.target as HTMLInputElement).value;
        let tmp = this.getUpdatedCompanyInfo;
        if(fieldData.fieldName === 'officers'){
            tmp[fieldData.fieldName][fieldData.fieldIndex!] = newValue;
        }else{
            tmp[fieldData.fieldName] = newValue;
        }

        // const delta = diff.getDiff(updatedCompanyInfo, companyInfo);
        // console.log(delta);
        this.setUpdatedCompanyInfo(tmp);
        // console.log(updatedCompanyInfo);
    };

}

export default new RenewalStore();