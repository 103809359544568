// export const main = '#f87822';
export const main = '#1B1464';
export const highlight = '#C72127';
export const shadow = 'rgba(66,66,66,0.5)';
export const background = '#2232681A';
export const grey = '#989898';
export const black = '#1a1a1a';
export const lightGrey = '#e0e0e0'

export const fyleProBlue = '#1B1464';
export const fyleProBlueAlternate = '#161050';
export const fyleProGrey = '#1F4E50';