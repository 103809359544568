import * as React from 'react';
import {observer} from "mobx-react";
import Store from "../store";
import ResponsiveAppBarLoggedIn from "./responsive-app-bar-logged-in";
import ResponsiveAppBarNew from "./responsive-app-bar-new";


const ResponsiveAppBarCombined = observer(({showLogin}:{showLogin:boolean}) => {
    return (
        Store.isLoggedIn ? <ResponsiveAppBarLoggedIn/> : <ResponsiveAppBarNew shrinkable={true}/>
    );
})
export default ResponsiveAppBarCombined;