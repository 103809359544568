import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import Public from "./Public";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Unsubscribe from "./Unsubscribe";
import Renew from "./Renew";
import Complete from "./Complete";
import ReceiptWrapper from "./ReceiptWrapper";
import {createTheme, ThemeProvider} from "@mui/material";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Public />,
    },
    {
        path: "/renew",
        element: <Renew />,
    },
    // {
    //     path: "/already-renewed",
    //     element: <App />,
    // },
    {
        path: "/complete",
        element: <Complete />,
    },
    {
        path: "/receipt",
        element: <ReceiptWrapper />,
    },
    // {
    //     path: "/dashboard",
    //     element: <Dashboard />,
    // },
    // {
    //     path:'/activate',
    //     element:<Activate/>,
    // },
    {
        path:'/terms-and-conditions',
        element:<TermsAndConditions/>,
    },
    {
        path:'/privacy',
        element:<PrivacyPolicy/>,
    },
    // {
    //     path:'/reset-password',
    //     element:<PasswordReset/>,
    // },
    // {
    //     path:'/login',
    //     element:<LoginPage/>,
    // },
    {
        path:'/unsubscribe',
        element:<Unsubscribe/>,
    }
]);

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            // 'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);