import {
    Box,
    CircularProgress,
    Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {shadow} from "../assets/colors";
import {DarkButton, InlineButton, LightButton} from "./buttons";
import ErrorMessage from "./error";
import Store from "../store";
import {postFetch} from "../api/client";
import diff from "recursive-diff";
import RenewalStore from "../stores/renewal-store";
import SignatureCanvas from 'react-signature-canvas';

export interface SignatureDialogProps {
    open: boolean;
    onClose: () => void;
    officers: object[];
    documentNumber: string;
}

export const SignatureDialog = (props: SignatureDialogProps) => {
    const {onClose, open, officers, documentNumber} = props;
    const [errorMessage, setErrorMessage] = useState("");
    const [generatingSignature, setGeneratingSignature] = useState(false);
    const [useTypedSignature, setUseTypedSignature] = useState(false);

    //Signature pad data
    let sigPad: SignatureCanvas;
    let clear = () => {
        sigPad.clear();
        setSignatureString('');
    }
    const onSignEnd = () => {
        setSignatureString(sigPad.getTrimmedCanvas()
            .toDataURL('image/png'));
        validateSignature(false);
    }

    const handleClose = () => {
        onClose();
        setGeneratingSignature(false);
        setUseTypedSignature(false);
        setSignatureString('');
        setValidSignature(false);
    };

    const [selectedOfficerId, setSelectedOfficerId] = useState('');

    const handleSelectedOfficerIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedOfficerId((event.target as HTMLInputElement).value);
        setSignatureString("");
        setErrorMessage("");
    };

    const [signatureString, setSignatureString] = useState('');
    const [validSignature, setValidSignature] = useState(false);

    useEffect(() => {
        validateSignature(false);
    }, [signatureString]);

    const handleSignatureChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setSignatureString((event.target as HTMLInputElement).value);
    };

    function validateSignature(displayError: boolean) {
        if (selectedOfficerId != '') {
            if (useTypedSignature) {
                let fullName = `${officers[selectedOfficerId]['firstName'].toLowerCase()} ${officers[selectedOfficerId]['lastName'].toLowerCase()}`;
                if (`${fullName.trim()}` != signatureString.toLowerCase().trim()) {
                    setValidSignature(false);
                    displayError ? setErrorMessage("Signature must match selected officer exactly.") : null;
                } else {
                    setErrorMessage('');
                    setValidSignature(true);
                }
            } else {
                if (signatureString == '') {
                    setValidSignature(false);
                    displayError ? setErrorMessage("Signature is required. Please sign before you continue.") : null;
                } else {
                    console.log("valid?");
                    setErrorMessage('');
                    setValidSignature(true);
                }
            }
        }

    }

    function signDocument() {
        validateSignature(true);
        if (validSignature) {
            //Call the server here to get the signature itself.

            Store.setSelectedOfficer(officers[selectedOfficerId] !== undefined ? officers[selectedOfficerId]['id'] : selectedOfficerId);
            //We should send the signature to the server here.
            setGeneratingSignature(true);
            postFetch({
                endpoint: `signature/${documentNumber}`, body: {
                    id: Store.getSelectedOfficer,
                    changes: diff.getDiff(JSON.parse(JSON.stringify(RenewalStore.getCompanyInfo)), JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo))),
                    signature: signatureString,
                    name: `${officers[selectedOfficerId]['firstName']} ${officers[selectedOfficerId]['lastName']}`,
                    documentNumber: documentNumber,
                }, authenticate: false
            }).then((response) => {
                response.json().then(() => {
                    // Store.setSignatureHash(body.message);
                    Store.setSignatureHash("Signature Recorded");
                    handleClose();
                })


            });

        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            signDocument()
        }
    }

    return (
        <Dialog slotProps={{backdrop: {style: {backgroundColor: shadow}}}} onClose={handleClose} open={open}>

            {/*<Container className={"signature-dialog"} sx={{pb: 5}}>*/}
            <DialogTitle>Authorize Renewal</DialogTitle>
            <DialogContent sx={{overflowX: 'hidden'}}>


                {generateSignatureNames()}


                {(selectedOfficerId == '') ? <>
                    <Divider/>
                    <Typography variant={"h4"} textAlign={"center"} sx={{p: 2}}>Please select an officer to begin
                        signing.</Typography>
                </> : <>
                    {useTypedSignature ?
                        <>
                            <Divider/>
                            <Container disableGutters
                                       sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 4}}>
                                <TextField className={"signature-box"}
                                           disabled={selectedOfficerId == ''}
                                           sx={{width: {xs: '95%', md: 'calc(100% - 80px)'}}}
                                           label={selectedOfficerId == '' ? "Please Select Officer" : "Sign Here"}
                                           placeholder={"Please Type your name as it appears above"}
                                           focused
                                           value={signatureString}
                                           onBlur={() => validateSignature(true)}
                                           onChange={handleSignatureChange}
                                           onKeyDown={handleKeyDown}
                                />
                            </Container>
                        </> :
                        <>
                            <Box component={"div"} sx={{
                                width: 'fit-content',
                                m: 'auto',
                                border: 'solid',
                                borderWidth: '1px',
                                color: 'lightgrey'
                            }}>
                                <SignatureCanvas ref={(ref: SignatureCanvas) => {
                                    sigPad = ref
                                }} onEnd={onSignEnd} penColor='red'
                                                 canvasProps={{width:250, height:200, className: 'sigCanvas' }}/>
                            </Box>
                            <Typography textAlign={"center"} sx={{fontSize: 12, mb: 0, color: 'lightgrey'}}>Sign
                                Here</Typography>
                            {(signatureString != '') ? <InlineButton sx={{m: 2}} onClick={clear}>
                                Clear Signature
                            </InlineButton> : null}
                            <Container maxWidth={'sm'}
                                       sx={{p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <LightButton onClick={() => {
                                    setUseTypedSignature(true);
                                    setSignatureString('');
                                }}>Unable to sign with your finger? Tap here.</LightButton>

                            </Container>
                        </>}

                </>}


                <Typography sx={{p: 2}}>The signature above must match that of the individual signing this form
                    electronically or be made with the permission of the individual represented above. By signing and
                    submitting this form you are agreeing to either be this person or be authorized to complete this
                    form on their behalf, and that your electronic signature is the equivalent of a handwritten
                    signature.</Typography>

            </DialogContent>
            <DialogActions sx={{m: 0, p: 0, display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
                <ErrorMessage message={errorMessage}/>

                <Container maxWidth={'sm'} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {generatingSignature ? <CircularProgress/> :
                        <DarkButton onClick={signDocument} disabled={!validSignature} sx={{p: 2, width: '100%', mb:5}}
                                    disableElevation
                                    variant={'contained'}>
                            Sign Now
                        </DarkButton>}

                </Container>
            </DialogActions>


            {/*{signatureString*/}
            {/*    ? <img*/}
            {/*        src={signatureString}/>*/}
            {/*    : null}*/}

            {/*</Container>*/}


        </Dialog>
    );

    function generateSignatureNames() {
        return (<FormControl sx={{display: 'flex', width: '100%'}}>
            <FormLabel id="demo-controlled-radio-buttons-group">Please Select Signing Officer</FormLabel>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedOfficerId}
                onChange={handleSelectedOfficerIdChange}>


                {officers.map((officer, index) =>
                    <FormControlLabel key={index} sx={{py: 2, m: 0, width: '100%'}} value={index}
                                      control={<Radio/>}
                                      label={
                                          <TextField
                                              sx={{zIndex: '999', pointerEvents: 'none', width: '100%'}}
                                              disabled
                                              label={'Title: ' + officer['title']}
                                              defaultValue={`${officer['firstName']} ${officer['lastName']}`}
                                          />
                                      }
                    />
                )}


            </RadioGroup>
        </FormControl>)
    }
}