import React from 'react';
import {Container, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import BodyText from '../tiny/body-text';
import {fyleProBlue, fyleProGrey} from '../../assets/colors';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ComputerIcon from '@mui/icons-material/Computer';
import ShieldIcon from '@mui/icons-material/Shield';
import Box from "@mui/material/Box";
import {VerifiedUser, VerifiedUserOutlined} from "@mui/icons-material";

const SectionContainer = ({children}: { children: React.ReactNode }) => (
    <Container disableGutters sx={{display: "flex", flexDirection: "column", padding: 2, mt: 10}}>
        {children}
    </Container>
);

const IconCard = ({
                      icon,
                      title,
                      content,
                  }: {
    icon: React.ReactNode;
    title: string;
    content: React.ReactNode;
}) => (
    <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            mb: 4,
        }}
    >
        <Box component={"div"} sx={{fontSize: {xs: 96,  md: 144}, color: '#1B1464', mb: 2}}>{icon}</Box>

        <Typography
            sx={{color: '#1B1464', fontWeight: 600, mb: 1, typography: {xs: 'h4', md: 'h3'}}}
        >
            {title}
        </Typography>

        <Typography sx={{color: '#1B1464', lineHeight: 2, typography: {xs: 'h6', md: 'h5'}}}>
            {content}
        </Typography>
    </Container>
);

const OurServices = () => {
    return (
        <>
            <section>
                <div className="anchor" id="our-services"/>
                <SectionContainer>
                    <Typography component="div" sx={{mb: 1, typography: {xs: 'h4', md: 'h3', lg: 'h2'}}}>
                        <span style={{color: fyleProGrey, fontWeight: 600}}>
                            We'll take it from{' '}
                        </span>
                        <span style={{color: fyleProBlue, fontWeight: 900}}>
                            here
                        </span>
                    </Typography>

                    <BodyText pl={0} mt={0}>
                        FylePro is a digital corporate document center and filing
                        service. Our goal is to provide efficient service through our
                        easy-to-use platform and a transparent pricing model that offers
                        value to both small business owners and larger corporate
                        structures alike.
                    </BodyText>

                    <Container
                        sx={{
                            mt: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <IconCard
                            icon={<StorefrontIcon fontSize={"inherit"}/>}
                            title="Starting a New Business"
                            content={
                                <>
                                    Incorporate as an LLC, S-Corp or C-Corp with your
                                    local State Filing Agency.
                                    <br/>
                                    Register your company for a Federal Tax ID (EIN)
                                    Number.
                                </>
                            }
                        />
                        <IconCard
                            icon={<ComputerIcon fontSize={"inherit"}/>}
                            title="Establish an Online Presence"
                            content={
                                <>
                                    Purchase a website domain.
                                    <br/>
                                    Post terms of service on your website.
                                </>
                            }
                        />
                        <IconCard
                            icon={<VerifiedUserOutlined fontSize={"inherit"}/>}
                            title="Maintain Compliance"
                            content={
                                <>
                                    Make sure you are up to date on required postings in
                                    your physical location.
                                    <br/>
                                    File (DBA) Corporate Fictitious Name with your local
                                    State Filing Agency.
                                    <br/>
                                    File annual renewal reports with your local State Filing Agency.
                                </>
                            }
                        />
                    </Container>
                </SectionContainer>
            </section>
        </>
    );
};

export default observer(OurServices);