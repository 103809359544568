import {Typography} from "@mui/material";
import React from "react";

function BodyText({children, pl = 3, pr = 3, pt = 3, pb = 3, mt = 2}) {
    return <Typography sx={{
        // color: "white",
        textAlign: "left",
        pl: pl,
        pr: pr,
        pt: pt,
        pb: pb,
        mt: mt,
        fontSize: {xs: "1.1rem", md: "1.2rem"},
        lineHeight: {xs: 1.5, md: 1.3},
        fontWeight: 500
    }}>
        {children}
    </Typography>;
}

export default BodyText