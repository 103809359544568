import React from "react";
import {Container, Box, Typography, Grid, Button, useTheme, useMediaQuery} from "@mui/material";
import {observer} from "mobx-react";
import WaveScene from "../WaveScene";
import {fyleProBlue, fyleProGrey} from "../../assets/colors";
import gradientBackground from "../../assets/gradient-background.png";

const Tile = ({text}: { text: string }) => (
    <Grid item>
        <Box
            component="div"
            sx={{
                width: 150,
                height: 40,
                borderRadius: 5,
                background: fyleProBlue,
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography sx={{fontWeight: 900, color: "white"}}>
                {text}
            </Typography>
        </Box>
    </Grid>
);

// Main Component
const HomeTileSection = () => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md")); // Check if screen is at least "md" size


    const tileData = [
        "LLC & Corporate Formations",
        "Corporate Annual Report Filings",
        "Fictitious Name (DBA) Registration",
        "Labor Law Posters",
        "Website Terms of Service",
        "EIN Registration",
    ];

    return (
        <section>
            <div className="anchor" id="from-idea-to-entity"/>

            <Box component={"div"}
                 sx={{
                     paddingTop: {xs: 40, md: 40},
                     paddingBottom: {xs: 10, md: 10},
                     backgroundImage: `url(${gradientBackground})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'bottom',
                     display: "flex",
                     flexDirection: "column",
                     padding: 2,
                     textAlign: "center",
                     alignItems: "center",
                     justifyContents:'center',
                     // minHeight: "70vh",
                 }}
            >
                <Container disableGutters sx={{maxWidth:820,}}>
                    <Typography component="div"
                                sx={{fontWeight: 600, color: "white", typography: {xs: 'h4', md: 'h2'}}}>
                        From <span style={{color: "white", fontWeight: 900}}>Idea</span>
                        <Box component={"svg"} sx={{height: {xs: 30, md: 40}, marginLeft: 3, marginRight: 3,}}
                             viewBox="0 0 67.82 30.91">
                            <polygon
                                style={{fill: "white"}}
                                points="42.56 9.75 0 9.75 0 21.17 42.56 21.17 41.23 30.91 67.82 15.28 41.23 0 42.56 9.75"
                            />
                        </Box>
                        <span style={{color: "white", fontWeight: 900}}>Entity:</span>
                    </Typography>
                    <Typography component="div"
                                sx={{fontWeight: 600, color: "white", typography: {xs: 'h4', md: 'h2'}}}>
                        Incorporate with <span style={{color: "white", fontWeight: 900}}>Confidence</span>
                    </Typography>
                    {!isMdUp &&
                        <Container sx={{mt: 2, mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <WaveScene size={150}/>
                        </Container>
                    }

                    <Grid container spacing={10}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                alignItems: {xs: "center", md: "flex-end"},
                                justifyContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Box component={"div"} sx={{maxWidth: 550,}}>
                                <Typography
                                    sx={{color: "white", fontSize: "2.5rem", textAlign: {xs: 'center', md: 'right'}}}>
                                    Starting and managing a business has never been easier. Let us take care of the filings
                                    and
                                    forms, so you can focus on building your brand.
                                </Typography>

                                {/*<Container sx={{display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'}}}>*/}
                                {/*    <Button>*/}
                                {/*        <Container sx={{*/}
                                {/*            background: fyleProBlue,*/}
                                {/*            color: "white",*/}
                                {/*            padding: 2,*/}
                                {/*            borderRadius: 4,*/}
                                {/*            fontSize: "1.3rem",*/}
                                {/*            fontWeight: '700'*/}
                                {/*        }}>*/}
                                {/*            List of Services {">"}*/}
                                {/*        </Container>*/}
                                {/*    </Button>*/}
                                {/*    <Button>*/}
                                {/*        <Container sx={{*/}
                                {/*            background: "transparent",*/}
                                {/*            color: fyleProBlue,*/}
                                {/*            padding: 2,*/}
                                {/*            borderRadius: 4,*/}
                                {/*            fontSize: "1.3rem",*/}
                                {/*            fontWeight: '700'*/}
                                {/*        }}>*/}
                                {/*            Contact Us {">"}*/}
                                {/*        </Container>*/}
                                {/*    </Button>*/}
                                {/*</Container>*/}

                            </Box>
                        </Grid>
                        {isMdUp && (
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    flexDirection: "column",
                                }}
                            >
                                <WaveScene/>
                            </Grid>
                        )}
                    </Grid>


                    {/*<Grid*/}
                    {/*    container*/}
                    {/*    spacing={2}*/}
                    {/*    justifyContent="space-around"*/}
                    {/*    sx={{maxWidth: 800, marginTop: 5}}*/}
                    {/*>*/}
                    {/*    {tileData.map((text, index) => (*/}
                    {/*        <Tile key={index} text={text}/>*/}
                    {/*    ))}*/}
                    {/*</Grid>*/}
                </Container>



            </Box>
        </section>
    );
};

export default observer(HomeTileSection);