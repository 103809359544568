import React from 'react'
import {Box, Container, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {fyleProBlue, fyleProGrey} from "../../assets/colors";


const SectionContainer = ({children}: { children: React.ReactNode }) => (
    <Container disableGutters sx={{display: "flex", flexDirection: "column", padding: 2, mt: 10}}>
        {children}
    </Container>
);


const PricingTile = ({text, amount, showFeeDisclosure = true}: {
    text: string,
    amount: string,
    showFeeDisclosure?: boolean
}) => (
    <Box
        component="div"
        sx={{
            // width:'100%',
            // width: 600,
            // minWidth:300,
            height: 40,
            borderRadius: 5,
            background: fyleProBlue,
            p: 3,
            mb: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }}
    >
        <Typography
            sx={{width: '50%', fontWeight: 900, color: "white", textAlign: 'start', typography: {xs: 'h7', md: 'h5'}}}>
            {text}
        </Typography>
        <Container disableGutters sx={{width: '50%', textAlign: 'end'}}>
            <Typography variant={'h4'} sx={{fontWeight: 900, color: "white",}}>
                {amount}
            </Typography>

            {showFeeDisclosure && (
                <Typography variant="body2" sx={{fontWeight: 400, color: "white"}}>
                    + Local State Fees
                </Typography>
            )}
        </Container>

    </Box>
);

const Pricing = () => {
    return (
        <>
            <section>
                <div className="anchor" id="pricing"/>
                <SectionContainer>
                    <Typography component="div" sx={{mb: 5, typography: {xs: 'h4', md: 'h3'}}}>
                        <span style={{color: fyleProBlue, fontWeight: 900}}>Transparent </span>
                        <span style={{color: fyleProGrey, fontWeight: 600}}>Pricing. One flat cost, </span>
                        <span style={{color: fyleProBlue, fontWeight: 900}}>no hidden fees.</span>
                    </Typography>
                    <Container disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
                        <PricingTile text={"Corporate Formation Service"} amount={"$99"}/>
                        <PricingTile text={"Website Terms of Service"} amount={"$49"} showFeeDisclosure={false}/>
                        <PricingTile text={"Fictitious Name (DBA) Registration Service"} amount={"$29"}/>
                        <PricingTile text={"EIN Registration Service"} amount={"$49"} showFeeDisclosure={false}/>
                        <PricingTile text={"Corporate Annual Report Filing Service"} amount={"$99"}/>
                    </Container>
                </SectionContainer>
            </section>

        </>
    )
}

export default observer(Pricing);
