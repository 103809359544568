export function similarity(s1: string, s2: string): number {
    let longer: string = s1;
    let shorter: string = s2;

    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }

    const longerLength: number = longer.length;

    if (longerLength === 0) {
        return 1.0;
    }

    const distance = editDistance(longer, shorter);
    return (longerLength - distance) / longerLength;
}


function editDistance(s1: string, s2: string): number {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    // Create a 1D array to store costs
    const costs: number[] = [];

    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0) {
                costs[j] = j;
            } else if (j > 0) {
                let newValue = costs[j - 1];
                if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                    newValue = Math.min(
                        Math.min(newValue, lastValue),
                        costs[j]
                    ) + 1;
                }
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
        }
        if (i > 0) {
            costs[s2.length] = lastValue;
        }
    }

    return costs[s2.length];
}