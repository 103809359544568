import React from 'react'
import {Avatar, Container, Grid, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import {observer} from "mobx-react";
import BodyText from "../tiny/body-text";
import {fyleProBlue, fyleProBlueAlternate, fyleProGrey} from "../../assets/colors";
import Box from "@mui/material/Box";
import Slider from "react-slick";

const SectionContainer = ({children}: { children: React.ReactNode }) => (
    <Container disableGutters sx={{ maxWidth:820, display: "flex", flexDirection: "column", padding: 2, mt: 5, mb: 5,}}>
        {children}
    </Container>
);

const StyledTile = ({text}: { text: string }) => {
    return (
        <Box component={"div"}
             sx={{
                 minWidth: 100,
                 minHeight: 100,
                 backgroundColor: fyleProGrey,
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "center",
                 borderRadius: 6,
             }}
        >
            <Typography
                sx={{
                    fontSize: {xs: "1.0rem", md: "1.5rem"},
                    fontWeight: 500,
                    color: "white", // Text color
                    letterSpacing: "0.1em", // Optional: spacing between letters
                }}
            >{text}
            </Typography>
        </Box>
    );
};

const CorporationCard = ({
                             title,
                             avatar,
                             description,
                             height,
                         }: {
    title: string;
    avatar: string;
    description: string | React.ReactNode;
    height: string;
}) => (
    <Container disableGutters sx={{
        background: fyleProBlueAlternate,
        color: "white",
        mb: 2,
        borderRadius: 2,
        pt: 2,
        pb: 2,
        height: height
    }}>
        <Container sx={{display: 'flex', alignItems: 'flex-end'}}>
            <StyledTile text={avatar}/>
            <Typography
                // variant="h4"
                textAlign="left"
                sx={{ml: 2, mt: 1, fontWeight: '900!important', color: "white", typography: {xs: 'h5', md: 'h4'}}}
            >
                {title}
            </Typography>
        </Container>

        <BodyText>{description}</BodyText>
    </Container>
);

const ResponsiveTileSection: React.FC<{}> = () => {
    const theme = useTheme();
    const isMedUp = useMediaQuery(theme.breakpoints.up('md')); // Check for 'md' breakpoint

    const cards = [
        <CorporationCard
            title={"LIMITED LIABILITY COMPANY"}
            avatar={"LLC"}
            height={'50%'}
            description={
                `An LLC, or Limited Liability Company, is a business structure that combines the pass-through taxation of 
                 a partnership or sole proprietorship with the limited liability of a corporation. This means that the 
                 owners (called members) are not personally liable for the company's debts or liabilities beyond the 
                 amount of their investment in the company. LLCs are a popular choice for small businesses and startups 
                 due to their flexibility, simplicity, and liability protection.`
            }
        />,
        <CorporationCard
            title={"S CORPORATION"}
            avatar={"S-Corp"}
            height={'50%'}
            description={
                `An S Corporation (S-Corp) is a special type of corporation that meets specific IRS requirements to avoid 
                 double taxation. Like a regular corporation (C-Corp), an S-Corp is a separate legal entity, providing 
                 limited liability protection to its owners (shareholders). However, unlike a C-Corp, an S-Corp passes 
                 its income, losses, deductions, and credits through to its shareholders for federal tax purposes. This 
                 means that the S-Corp itself is not taxed on its income; instead, shareholders report the income on 
                 their individual tax returns. S-Corps are popular for small to mid-sized businesses due to their tax 
                 advantages and flexibility in ownership.`
            }
        />,
        <CorporationCard
            title={"C CORPORATION"}
            avatar={"C-Corp"}
            height={'100%'}
            description={
                <>A C Corporation (C-Corp) is a legal structure for a business that is separate from its owners
                    (shareholders). Unlike a sole proprietorship or partnership, where the owners are personally
                    liable for the business's debts and liabilities, a C-Corp provides limited liability protection to
                    its shareholders. This means that the shareholders are generally not personally responsible for
                    the debts and obligations of the corporation.
                    <p/>
                    One of the key features of a C-Corp is that it is taxed as a separate entity from its owners. This
                    means that the corporation itself pays taxes on its income, and the shareholders also pay taxes on
                    any dividends or distributions they receive from the corporation. This can result in what is often
                    referred to as "double taxation," where the same income is taxed at both the corporate level and the
                    individual level.
                    <p/>
                    Despite the potential for double taxation, C-Corps offer several advantages, including the ability
                    to raise capital through the sale of stock, flexibility in ownership and management structure, and
                    potential tax benefits, such as the ability to deduct certain employee benefits and expenses.
                    C-Corps are often used by larger companies and those planning to go public or seek outside
                    investment.</>
            }
        />,
    ];

    const sliderSettings = {
        dots: true, // Show pagination dots
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return isMedUp ? (
        <Grid container spacing={2}>
            {/* Render the first two cards in one column */}
            <Grid item xs={12} md={6}>
                {cards.slice(0, 2).map((card, index) => (
                    // <Box component={'div'} key={index} sx={{ mb: 2 }}>
                        <>{card}</>
                    // </Box>
                ))}
            </Grid>

            {/* Render the third card in the second column */}
            <Grid item xs={12} md={6}>
                {cards.slice(2).map((card, index) => (
                    // <Box component={'div'} key={index}>
                        <>{card}</>
                    // </Box>
                ))}
            </Grid>
        </Grid>
    ) : (
        <Container disableGutters sx={{ mt: 5, mb: 2 }}>
            <Slider {...sliderSettings}>
                {cards.map((card, index) => (
                    <div key={index}>
                        {card}
                    </div>
                ))}
            </Slider>
        </Container>
    );
};
const TypesOfCorps = () => {
    return (
        <>
            <section>
                <div className="anchor" id="type-of-corps"/>
                <Box component={"div"}
                     sx={{
                         background: fyleProBlue,
                         display: "flex",
                         flexDirection: "column",
                     }}
                >
                    <SectionContainer>
                        <Typography component="div" sx={{mb: 5, typography: {xs: 'h4', md: 'h3', lg: 'h2'}}}>
                            <span style={{color: "white", fontWeight: 600}}>What type of </span>
                            <span style={{color: "white", fontWeight: 900}}>corporation </span>
                            <span style={{color: "white", fontWeight: 600}}>is right for me?</span>
                        </Typography>

                        <ResponsiveTileSection/>


                    </SectionContainer>
                </Box>


            </section>

        </>
    )
}

export default observer(TypesOfCorps);
