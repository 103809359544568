import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react";
import BodyText from "../tiny/body-text";
import { fyleProBlue, fyleProGrey } from "../../assets/colors";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LimitedLiabilityProtection from "../../assets/limited_liability_protection.svg";
import ProfessionalImage from "../../assets/professional_image.svg";
import TaxFlexibility from "../../assets/tax_flexibility.svg";
import RaisingCapital from "../../assets/raising_capital.svg";

const SectionContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Container disableGutters sx={{ display: "flex", flexDirection: "column", padding: 2, mt: 10 }}>
        {children}
    </Container>
);

const DisclaimerText: React.FC = () => (
    <Typography sx={{ mt: 2 }} variant="body2">
        DISCLAIMER: FylePro does not provide tax, legal or accounting advice. This material has been prepared for
        informational purposes only, and is not intended to provide, and should not be relied on for, tax, legal or
        accounting advice. You should consult your own tax, legal and accounting advisors before engaging in any
        transaction.
    </Typography>
);

interface IncorporateTileProps {
    title: string;
    description: string;
    icon: string; // Pass in the icon component
}

const IncorporateTile: React.FC<IncorporateTileProps> = ({ title, description, icon }) => (
    <Box
        component="div"
        sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            color: fyleProBlue,
        }}
    >
        <Container sx={{height: 125, mb: 4, width: 'fit-content'}}>
            <img
                src={icon}
                alt={`${title} icon`}
                style={{height:110}}
            />
        </Container>

        <Typography variant="h4" sx={{fontWeight: 700}}>
            {title}
        </Typography>
        <Typography variant="h5" sx={{fontWeight: 400, lineHeight: 1.7}}>
        {description}
        </Typography>
    </Box>
);

const ResponsiveTileSection: React.FC<{ tiles: IncorporateTileProps[] }> = ({ tiles }) => {
    const theme = useTheme();
    const isMedUp = useMediaQuery(theme.breakpoints.up('md')); // Check for 'md' breakpoint

    const sliderSettings = {
        dots: true, // Show pagination dots
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return isMedUp ? (
        <Grid container sx={{ mt: 10 }} spacing={4}>
            {tiles.map(({ title, description, icon }, index) => (
                <Grid item xs={6} key={index}>
                    <IncorporateTile title={title} description={description} icon={icon} />
                </Grid>
            ))}
        </Grid>
    ) : (
        <Container disableGutters sx={{ mt: 5, mb: 2 }}>
            <Slider {...sliderSettings}>
                {tiles.map(({ title, description, icon }, index) => (
                    <div key={index}>
                        <IncorporateTile title={title} description={description} icon={icon} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

const WhyIncorporate: React.FC = () => {
    const tileList: IncorporateTileProps[] = [
        {
            title: "Limited Liability Protection",
            description: "If you want to protect your personal assets from business debts and liabilities, forming a corporation can provide limited liability protection to its owners.",
            icon: LimitedLiabilityProtection,
        },
        {
            title: "Professional Image",
            description: "Operating as a corporation can convey a sense of professionalism and credibility to customers, suppliers, and potential partners.",
            icon: ProfessionalImage,
        },
        {
            title: "Tax Flexibility",
            description: "Depending on your business goals and tax situation, a C-Corp or an S-Corp structure may offer tax advantages, such as the ability to deduct certain expenses or to pass through income to shareholders without double taxation.",
            icon: TaxFlexibility,
        },
        {
            title: "Raising Capital",
            description: "If you plan to raise capital by selling stock or attracting investors, a corporation may be a more attractive option than other business structures.",
            icon: RaisingCapital,
        },
    ];

    return (
        <>
            <section>
                <div className="anchor" id="why-incorporate" />
                <SectionContainer>
                    <Typography component="div" sx={{ typography: { xs: 'h4', md: 'h3', lg: 'h2' } }}>
                        <div style={{ color: fyleProGrey, fontWeight: 600 }}>Understanding when's the right time to</div>
                        <div style={{ color: fyleProBlue, fontWeight: 900 }}>file a corporation.</div>
                    </Typography>

                    <DisclaimerText />

                    <BodyText pl={0} pt={4} pb={0}>
                        You should consider filing for a corporation, such as a C-Corp or an S-Corp, when you want to
                        establish a separate legal entity for your business that provides limited liability protection
                        to its owners (shareholders). Here are some common scenarios when filing for a corporation might
                        be appropriate:
                    </BodyText>

                    <ResponsiveTileSection tiles={tileList} />

                    <BodyText pl={0} pt={4} pb={0}>
                        Before deciding to file for a corporation, it's advisable to consult with a legal or tax advisor to understand the specific implications for your business and to ensure that you comply with all legal and regulatory requirements.
                    </BodyText>
                </SectionContainer>
            </section>
        </>
    );
};

export default observer(WhyIncorporate);